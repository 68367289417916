<template>
  <fragment>
    <!-- popup подтверждающий удаление -->
    <DeletePopup
      title="заявку"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteServiceTask"
    />
    <!-- /popup подтверждающий удаление-->
    <MainLayout class="list_radius-all">
      <template v-slot:navbar-title>{{
        sourceObject ? sourceObject.title : ""
      }}</template>

      <template
        v-if="currentRole == 70 || currentRole == 50"
        v-slot:navbar-btn-wrap
      >
        <button @click="clickDelete" class="btn btn-delete btn_color_red">
          удалить заявку
        </button>
      </template>

      <div class="keep keep-organizations-data">
        <div class="keep__title">
          <p class="keep__text">Данные заявки</p>

          <div class="d-flex  ml-auto">
            <router-link
              :to="`/editServiceTask/${urlQuery.id}`"
              v-if="![40].includes($store.getters.getCurrentRole)"
              tag="button"
              class="btn btn-edit btn_color_grey mr-5"
            >
              редактировать заявку
            </router-link>
          </div>
        </div>
        <div class="keep__table-wrap">
          <BasicTableWithoutHeader
            v-for="(table, index) in tableInfo"
            :key="index"
            :table-data="table"
          >
            <template v-if="index === 0" #item.3.value="{value}">
              <span :style="{ color: value === 1 ? 'red' : '' }">{{
                dictionariesHelper.serviceTaskPriority[value]
              }}</span>
            </template>
            <template v-if="index === 0" #item.5.value="{value}">
              <div v-if="![30].includes($store.getters.getCurrentRole)">
                <ValidationSelectField
                  :items="statusArr"
                  :value="value"
                  @input="changeStatus($event)"
                >
                </ValidationSelectField>
              </div>
              <div v-else>{{ dictionariesHelper.serviceTask[value] }}</div>
            </template>
          </BasicTableWithoutHeader>
        </div>
        <BasicTableWithoutHeader :table-data="tableFooter" />
      </div>
      <div
        slot="list2"
        class="list list_radius-all container list-organizations-data"
        style="margin-top: -50px;"
      >
        <div class="list__content-adding">
          <ValidationForm
            class="form card"
            ref="parentCommentForm"
            ref-name="commentForm"
            v-slot:default="{ handleSubmit }"
          >
            <div class="inputs">
              <p class="list-organizations-data__text">Новый комментарий</p>
              <div class="grid-column_1 grid-column_span_2 input-field-wrap">
                <div class="input-field input-field_textarea">
                  <ValidationTextareaField
                    class="validate-textarea-field"
                    v-model="comment"
                    label="Комментарий"
                    validate-name="комментарий"
                    rules="required|max:500"
                  >
                    <template v-slot:default>
                      asdasda
                    </template>
                  </ValidationTextareaField>
                </div>
                <div class="input-field-wrap__text-wrap">
                  <p class="input-field-wrap__text-info">
                    Максимальная длина сообщения 500 символов
                  </p>
                  <p
                    class="input-field-wrap__text-status input-field-wrap__text-status_active"
                  >
                    Уведомление будет состоять из 2-х сообщений
                  </p>
                  <p
                    class="input-field-wrap__text-status input-field-wrap__text-status_warning"
                  >
                    Превышен лимит количества символов
                  </p>
                </div>
              </div>
              <div class="flex-ver-center grid-column_span_2">
                <button
                  @click.prevent="resetCommentForm"
                  class="btn btn_color_white"
                >
                  Отмена
                </button>
                <button
                  type="submit"
                  class="btn btn_color_green"
                  @click.prevent="handleSubmit(createComment)"
                >
                  создать
                </button>
              </div>
              <p class="list-organizations-data__text grid-column_1">
                Комментарии
              </p>
            </div>
          </ValidationForm>

          <div
            v-for="item in commentsObject.results"
            v-bind:key="item.id"
            class="comment-wrap"
          >
            <div class="comment">
              <p class="comment__title">
                {{ beautifyFormatTime(item.created_at) }}
                {{ getRoleTitle(item.user_details.role_groups) }} —
                {{ item.user_details.full_name }}
              </p>
              <p class="comment__text">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  </fragment>
</template>

<style>
.validate-textarea-field .v-input__slot:before,
.validate-textarea-field .v-input__slot:after {
  display: none !important;
}

.validate-textarea-field {
  width: 100%;
}

.validate-textarea-field label {
  display: none;
}

.validate-textarea-field textarea {
  resize: none;
}

.v-textarea {
  padding-top: 0;
}
</style>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import * as query from "@/helpers/query";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import _chunk from "lodash/chunk";
import { Fragment } from "vue-fragment";
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import rolesDictionary from "@/helpers/userInterface";
import { patchServiceTaskByIdRequest } from "@/helpers/api/serviceTasks";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import { deleteServiceTaskByIdRequest } from "@/helpers/api/serviceTasks";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import DeletePopup from "@/components/popup/DeletePopupCommon";

export default {
  name: "ServiceTaskPage",
  data() {
    return {
      dictionariesHelper: dictionariesHelper,
      statuses: [
        { value: "in-process", text: "в работе", roles: [50, 40] },
        { value: "finished", text: "выполнена", roles: [50, 40] },
        { value: "closed", text: "закрыта", roles: [50] },
        { value: "repeated", text: "Повторная", roles: [50] },
        { value: "dismissed", text: "Отклонена", roles: [50, 40] }
      ],
      type: "serviceTask",
      queries: query.serviceTask,
      test: "",
      dialog: false
    };
  },
  components: {
    ValidationSelectField,
    ValidationForm,
    MainLayout,
    BasicTableWithoutHeader,
    Fragment,
    ValidationTextareaField,
    DeletePopup
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    statusArr() {
      return this.statuses.filter(el => {
        return el.roles.includes(this.currentRole);
      });
    },
    comment: {
      get() {
        return this.$store.getters.getCommentForm.commentText.value;
      },
      set(newValue) {
        this.$store.commit("setComment", {
          formName: "commentForm",
          field: "commentText",
          value: newValue
        });
      }
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getServiceTaskInfo;
    },
    commentsObject() {
      return this.$store.getters.getServiceTaskComments;
    },
    tableInfo() {
      let sourceObject = this.sourceObject;
      let array = [
        {
          text: "ID",
          value: sourceObject.id
        },
        {
          text: "Дата создания",
          value: sourceObject.created_at
            ? this.beautifyFormatTime(sourceObject.created_at)
            : ""
        },
        {
          text: "Дата изменения",
          value: moment(sourceObject.updated_at * 1000).isValid()
            ? moment(sourceObject.updated_at * 1000).format("DD.MM.YYYY")
            : "-"
        },
        {
          text: "Приоритет",
          value: sourceObject.priority
        },
        {
          text: "Срок выполнения",
          value: sourceObject.deadline_at
            ? this.beautifyFormatTime(sourceObject.deadline_at)
            : ""
        },
        {
          text: "Статус",
          value: sourceObject.status
        },
        {
          text: "Инициатор",
          value: dictionariesHelper.serviceTask[sourceObject.init_type]
        },
        {
          text: "Тема",
          value: sourceObject.title
        },
        {
          text: "Тип заявки",
          value: dictionariesHelper.serviceTask[sourceObject.task_type]
        },
        {
          text: "Объект",
          value: sourceObject?.object_details?.name
        },
        {
          text: "Адрес объекта",
          value: sourceObject?.object_details?.address
        },
        {
          text: "Контроллер",
          value: sourceObject?.controller_details?.name
        }
      ];
      return _chunk(array, Math.ceil(array.length / 2));
    },
    tableFooter() {
      let sourceObject = this.sourceObject;
      return [
        {
          text: "Текст комментария",
          value: sourceObject.description
        }
      ];
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageServiceTaskInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchServiceTaskComments").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageServiceTaskInfo").finally(() => {
        this.isDateLoaded = false;
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchServiceTaskComments").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  methods: {
    clickDelete() {
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteServiceTask() {
      deleteServiceTaskByIdRequest({ id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.serviceTask)
          );
        })
        .then(() => {
          this.$router.go(-1);
        });
    },

    changeStatus(status) {
      patchServiceTaskByIdRequest({
        id: this.urlQuery.id,
        data: { status: status }
      });
    },
    createComment() {
      this.$store.dispatch("createComment");
      this.isDateLoaded = true;
      this.$store.dispatch("fetchServiceTaskComments").finally(() => {
        this.isDateLoaded = false;
      });
      this.resetCommentForm();
    },
    beautifyFormatTime(unixTimeValue) {
      return format(
        fromUnixTime(Number(unixTimeValue)),
        "dd.MM.yyyy в HH:mm:ss"
      );
    },
    getRoleTitle(roles) {
      if (roles && roles.length) return rolesDictionary[roles[0].role];
      return "Пользователь";
    },
    resetCommentForm() {
      this.comment = "";
      this.$nextTick(() => {
        this.$refs.parentCommentForm.$refs.commentForm.reset();
      });
    }
  }
};
</script>
